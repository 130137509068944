import { CheckIcon, SearchIcon } from "@fonoa/ui-components/icons";
import { Input } from "@fonoa/ui-components/input";
import { DataAttributes, FontSize } from "@fonoa/ui-components/types";
import { convertDataAttributes } from "@fonoa/ui-components/utils";
import classNames from "classnames";
import { isValidElement, ReactElement, ReactNode, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

export type Item<TItemValue extends string = string> = {
  value: TItemValue;
  label: ReactNode;
};

type Size = "small" | "medium" | "large";

export interface SelectableListProps<TItemValue extends string = string> {
  items: Item<TItemValue>[];
  value?: string | null;
  onChange?: (selected: TItemValue) => void;
  dataAttributes?: DataAttributes;
  searchable?: boolean;
  showSelectedFirst?: boolean;
  size?: Size;
  alternatingBackground?: boolean;
}

const SIZE_MAPS: Record<Size, `${FontSize}${string}`> = {
  small: "text-xs py-2",
  medium: "text-sm py-[9px]",
  large: "text-sm py-[13px]",
};

const getInnerText = (el: ReactElement) => {
  let children;
  if (Array.isArray(el.props?.children)) {
    children = el.props?.children;
  } else if (el.props?.children) {
    children = [el.props?.children];
  } else {
    children = [];
  }

  return children.reduce((allText: string, child: ReactNode) => {
    if (typeof child === "string") {
      return allText + child;
    } else if (isValidElement(child)) {
      return allText + getInnerText(child);
    } else {
      return allText;
    }
  }, "");
};

export default function SelectableList<TItemValue extends string>({
  value,
  onChange,
  items,
  dataAttributes = {},
  searchable = false,
  showSelectedFirst = false,
  size = "medium",
  alternatingBackground,
}: SelectableListProps<TItemValue>) {
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<string>(value || "");

  useEffect(() => {
    setSelected(value || "");
  }, [value, setSelected]);

  const dataAttr = convertDataAttributes(dataAttributes);

  const filteredItems = useMemo(() => {
    if (!search) return items;

    return items.filter((i) => {
      if (isValidElement(i.label)) {
        // label is a react element; filter by the innerText
        return getInnerText(i.label).toLowerCase().includes(search.toLowerCase());
      } else {
        // label is a string; filter as-is
        return i.label?.toString().toLowerCase().includes(search.toLowerCase());
      }
    });
  }, [search, items]);

  const orderedItems = useMemo(() => {
    let newItems = filteredItems;

    if (showSelectedFirst) {
      const selectedItem = filteredItems.find((item) => item.value === selected);
      const unselectedItems = filteredItems.filter((item) => item.value !== selected);
      newItems = selectedItem ? [selectedItem, ...unselectedItems] : unselectedItems;
    }

    return newItems;
  }, [filteredItems, selected, showSelectedFirst]);

  return (
    <div
      className={classNames("flex flex-col bg-white", searchable ? "pb-2" : "py-2")}
      {...dataAttr}
    >
      {searchable && (
        <Input
          placeholder={intl.formatMessage({ defaultMessage: "Search Here", id: "/U51/5" })}
          value={search}
          color="PRIMARY_BLUE_900"
          onChange={(event) => setSearch(event.target.value)}
          className="rounded-t border border-blueGray200 py-2 px-5"
          fluid
          autoFocus
          rounded={false}
          leftIcon={SearchIcon}
        />
      )}
      {orderedItems.map((item, i) => (
        <button
          key={item.value}
          className={classNames(
            "flex cursor-pointer items-center justify-between px-4 transition hover:bg-blueGray25",
            SIZE_MAPS[size],
            selected === item.value ? "font-medium text-primaryBlue500" : "text-primaryBlue900",
            {
              "bg-blueGray10": alternatingBackground && i % 2 === 1,
            }
          )}
          onClick={() => {
            setSelected(item.value);
            onChange?.(item.value);
          }}
        >
          <span className="text-left">{item.label}</span>
          {selected === item.value ? <CheckIcon size={16} /> : null}
        </button>
      ))}
    </div>
  );
}
