import { ErrorCategory } from "@/lib/types/transaction";

import {
  DashboardAlertsResponse,
  ErrorBreakdownResponse,
  ReportingRateResponse,
  RevenueBreakdownPerCountryResponse,
  RevenueBreakdownResponse,
  ScenariosBreakdownResponse,
} from "./EInvoicingDashboardPage.types";

export const errorBreakdownMock: ErrorBreakdownResponse = {
  status: "success",
  data: [
    {
      code: ErrorCategory.TaxAuthority,
      count: 87,
      country_code: "PT",
    },
    {
      code: ErrorCategory.Validation,
      count: 2,
      country_code: "PT",
    },
    {
      code: ErrorCategory.General,
      count: 14,
      country_code: "PT",
    },
    {
      code: "ISSUE_TYPE_TAX_AUTHORITY" as ErrorCategory /* Not expected value */,
      count: 14,
      country_code: "PT",
    },
    {
      code: ErrorCategory.TaxAuthority,
      count: 187,
      country_code: "ES",
    },
    {
      code: ErrorCategory.Validation,
      count: 12,
      country_code: "ES",
    },
    {
      code: ErrorCategory.General,
      count: 114,
      country_code: "ES",
    },
    {
      code: "ISSUE_TYPE_TAX_AUTHORITY" as ErrorCategory /* Not expected value */,
      count: 114,
      country_code: "ES",
    },
  ],
};

export const documentsMock: ScenariosBreakdownResponse = {
  status: "success",
  data: [
    {
      code: "TRANSACTION_SCENARIO_UNSPECIFIED",
      count: 7,
    },
    {
      code: "TRANSACTION_SCENARIO_DEBIT",
      count: 6,
    },
    {
      code: "TRANSACTION_SCENARIO_CREDIT",
      count: 5,
    },
    {
      code: "TRANSACTION_SCENARIO_ADJUSTMENT",
      count: 4,
    },
    {
      code: "TRANSACTION_SCENARIO_FULL_CREDIT",
      count: 3,
    },
    {
      code: "TRANSACTION_SCENARIO_CANCELLATION",
      count: 2,
    },
    {
      code: "UNKNOWN",
      count: 1,
    },
  ],
};

export const reportingRateMock: ReportingRateResponse = {
  status: "success",
  data: {
    total_reported: 120509,
    total_error: 9880,
    chart_items: [
      {
        date: "2023-05-14T00:00:00Z",
        reported: 660,
        error: 75,
      },
      {
        date: "2023-05-15T00:00:00Z",
        reported: 920,
        error: 40,
      },

      {
        date: "2023-05-16T00:00:00Z",
        reported: 460,
        error: 75,
      },
      {
        date: "2023-05-17T00:00:00Z",
        reported: 312,
        error: 40,
      },
      {
        date: "2023-05-18T00:00:00Z",
        reported: 660,
        error: 55,
      },
      {
        date: "2023-05-19T00:00:00Z",
        reported: 1120,
        error: 40,
      },
      {
        date: "2023-05-20T00:00:00Z",
        reported: 860,
        error: 75,
      },
    ],
  },
};

export const totalRevenueMock: RevenueBreakdownResponse = {
  status: "success",
  data: [
    {
      currency_code: "USD",
      total_amount: 10000,
    },
    {
      currency_code: "EUR",
      total_amount: 12000,
    },
  ],
};

export const totalRevenuePerCountryMock: RevenueBreakdownPerCountryResponse = {
  status: "success",
  data: [
    {
      currency_code: "USD",
      country_code: "US",
      total_amount: 10000,
    },
    {
      currency_code: "EUR",
      country_code: "PT",
      total_amount: 12000,
    },
  ],
};

export const totalRevenueWithCountryPTasFilterMock: RevenueBreakdownPerCountryResponse = {
  status: "success",
  data: [
    {
      currency_code: "EUR",
      country_code: "PT",
      total_amount: 12000,
    },
  ],
};

export const alertsMock: DashboardAlertsResponse = {
  status: "success",
  data: [
    {
      type: "certificate_expiration",
      count: 1,
    },
    {
      type: "invalid_tax_id",
      count: 87,
    },
  ],
};

export const taxBreakdownPerRateMock = {
  data: [
    { rate: 16.32, currency_code: "MXN", total_amount: 100.23 },
    { rate: 10, currency_code: "EUR", total_amount: 50.23 },
  ],
  status: "success" as const,
};

export const taxBreakdownPerTypeMock = {
  data: [
    { regime: "IVA", currency_code: "MXN", total_amount: 100.23 },
    { regime: "VAT", currency_code: "EUR", total_amount: 50.23 },
  ],
  status: "success" as const,
};

export const taxBreakdownPerCountryMock = {
  data: [
    { country_code: "MX", currency_code: "MXN", total_amount: 100.23 },
    { country_code: "PT", currency_code: "EUR", total_amount: 50.23 },
  ],
  status: "success" as const,
};
