import { useIntl } from "react-intl";

import FilterItem from "@/components/Filters/FilterItem";
import SelectableList from "@/components/Form/SelectableList";
import { useCurrencyOptions } from "@/components/Utils/SelectOptions";
import { trpc } from "@/utils/trpc";

import { ReportingDashboardFilterProps } from "./ReportingDashboardFilters";

interface Props {
  onChange: (v: ReportingDashboardFilterProps) => void;
  values: ReportingDashboardFilterProps;
}

const CurrencySearch = ({ onChange, values }: Props) => {
  const intl = useIntl();

  const { currencyOptions } = useCurrencyOptions();

  const { data: codes } = trpc.eInvoicing.currencyCodes.useQuery({
    dateFrom: values.dateFrom?.toISOString(),
    dateTo: values.dateTo?.toISOString(),
  });

  const options =
    currencyOptions.filter(
      (o) => codes?.data && codes.data.some((d) => d.code.toUpperCase() === o.value.toUpperCase())
    ) || [];

  return (
    <FilterItem
      dataAttributes={{ cy: "currency-filter" }}
      onApply={(val) => onChange({ ...values, currencyCode: val })}
      active={Boolean(values.currencyCode)}
      withApplyButton={false}
      title={intl.formatMessage({ defaultMessage: "Currency", id: "55hdQy" })}
      value={values.currencyCode}
      renderCurrentValue={() => values.currencyCode}
      component={(itemValue) => (
        <div className="max-h-56 overflow-y-scroll">
          <SelectableList
            searchable
            showSelectedFirst
            items={options}
            onChange={(val) => {
              onChange({ ...values, currencyCode: val });
            }}
            value={itemValue}
          />
        </div>
      )}
    />
  );
};

export default CurrencySearch;
