import { ColumnAlign, Table, TableColumn } from "@fonoa/ui-components/table";
import { A, Typography } from "@fonoa/ui-components/typography";
import { endOfDay, startOfDay } from "date-fns";
import { FormattedMessage } from "react-intl";

import BigToggle from "@/components/Form/BigToggle";
import { ReportingDashboardFilterProps } from "@/features/E-Invoicing/DashboardPage/components/ReportingDashboardFilters/ReportingDashboardFilters";
import {
  ErrorBreakdownItem,
  ErrorBreakdownResponse,
} from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.types";
import { routes } from "@/lib/routes";

import { getErrorMessageFromCode } from "./ReportingErrorBreakdownSection.utils";

export type ErrorBreakdownView = "country" | "type";

export interface ErrorBreakdownProps {
  selectedView: ErrorBreakdownView;
  onSelectView(view: ErrorBreakdownView): void;
  data?: ErrorBreakdownResponse;
  loading: boolean;
  error: boolean;
  currentFilter: ReportingDashboardFilterProps;
}

export const ReportingErrorBreakdownSection = ({
  selectedView,
  onSelectView,
  data,
  loading,
  error,
  currentFilter,
}: ErrorBreakdownProps) => {
  const groupByType = selectedView === "type";
  const groupedResult = (data?.data ?? []).reduce((acc, curr) => {
    const { code, count, country_code } = curr;
    const key = groupByType ? code : country_code;

    if (!acc[key]) {
      acc[key] = { count, country_code, code };
    } else {
      acc[key].count += count;
    }

    return acc;
  }, {} as Record<string, ErrorBreakdownItem>);
  const result = Object.values(groupedResult ?? {});

  return (
    <>
      <div className="mb-1 flex">
        <Typography
          variant="h5"
          fontWeight="font-medium"
          color="text-blueGray900"
          spacing={{ mr: 2 }}
        >
          <FormattedMessage defaultMessage="Reporting error breakdown" id="hVQ7hI" />
        </Typography>
      </div>
      <div className="mt-5 mb-4">
        <Typography variant="h6" color="text-blueGray900" spacing={{ mb: 3 }}>
          <FormattedMessage defaultMessage="Breakdown by" id="VKqy57" />
        </Typography>
        <BigToggle
          selectedItemKey={selectedView}
          items={[
            { key: "type", label: "Type", onClick: () => onSelectView("type") },
            { key: "country", label: "Country", onClick: () => onSelectView("country") },
          ]}
        />
      </div>
      <div>
        {selectedView === "type" ? (
          <ErrorsBreakdownTable
            isLoading={loading}
            error={error}
            columns={getErrorsBreakdownPerTypeColumns({ currentFilter })}
            data={result}
          />
        ) : (
          <ErrorsBreakdownTable
            isLoading={loading}
            error={error}
            columns={getErrorsBreakdownPerCountryColumns({ currentFilter })}
            data={result}
          />
        )}
      </div>
    </>
  );
};

const ErrorsBreakdownTable = <T extends { count: number }>({
  isLoading,
  error,
  columns,
  data,
}: {
  isLoading: boolean;
  error: boolean;
  columns: TableColumn<T>[];
  data?: T[];
}) => {
  const sortedData = data ? data.sort((a, b) => b.count - a.count) : [];
  return (
    <div className="pt-1">
      <Table
        columns={columns}
        data={sortedData}
        narrowRows
        loading={isLoading}
        loadingRowLimit={3}
        error={error}
        emptyHeightAsRow
        emptyImage={<></>}
        customErrorComponent={
          <Typography variant="small" color="text-blueGray900" spacing={{ mt: 2.5 }}>
            <FormattedMessage
              defaultMessage="Something went wrong loading the data. Please try again later or contact us."
              id="31/x0B"
            />
          </Typography>
        }
      />
    </div>
  );
};

const getErrorsBreakdownPerTypeColumns = ({
  currentFilter,
}: {
  currentFilter: ReportingDashboardFilterProps;
}): TableColumn<ErrorBreakdownItem>[] => {
  const forceLocalTimeZone = (date: Date) =>
    new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  // the transactions page expects the dates to be in the local timezone
  const fromInLocalTimezone = startOfDay(forceLocalTimeZone(currentFilter.dateFrom));
  const toInLocalTimezone = endOfDay(forceLocalTimeZone(currentFilter.dateTo));

  return [
    {
      Header: "Type",
      accessor: "code",
      disableSortBy: true,
      headClassName: "text-xs",
      className: "text-xs",
      size: {
        maxWidth: 120,
      },
      Cell: ({ row }) => {
        const item = row.original;
        return (
          <A
            href={routes.eInvoicingTransactions(
              fromInLocalTimezone,
              toInLocalTimezone,
              item.code,
              currentFilter.direction,
              currentFilter.countryCode,
              currentFilter.currencyCode
            )}
            fontWeight="normal"
            dataAttributes={{
              cy: `${item.code}-count-link`,
            }}
          >
            {getErrorMessageFromCode(item.code)}
          </A>
        );
      },
    },
    {
      Header: "Transactions",
      accessor: "count",
      disableSortBy: true,
      noWrap: true,
      align: ColumnAlign.RIGHT,
      headClassName: "text-xs",
      className: "text-xs",
      Cell: ({ row }) => <>{row.original.count}</>,
    },
  ];
};

const getErrorsBreakdownPerCountryColumns = ({
  currentFilter,
}: {
  currentFilter: ReportingDashboardFilterProps;
}): TableColumn<ErrorBreakdownItem>[] => {
  const forceLocalTimeZone = (date: Date) =>
    new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  // the transactions page expects the dates to be in the local timezone
  const fromInLocalTimezone = startOfDay(forceLocalTimeZone(currentFilter.dateFrom));
  const toInLocalTimezone = endOfDay(forceLocalTimeZone(currentFilter.dateTo));

  return [
    {
      Header: "Country",
      accessor: "country_code",
      disableSortBy: true,
      headClassName: "text-xs",
      className: "text-xs",
      size: {
        maxWidth: 120,
      },
      Cell: ({ row }) => {
        const item = row.original;
        return (
          <A
            href={routes.eInvoicingTransactions(
              fromInLocalTimezone,
              toInLocalTimezone,
              "",
              currentFilter.direction,
              item.country_code,
              currentFilter.currencyCode,
              "failure"
            )}
            fontWeight="normal"
            dataAttributes={{
              cy: `${item.code}-count-link`,
            }}
          >
            {item.country_code ? (
              item.country_code.toUpperCase()
            ) : (
              <FormattedMessage defaultMessage="Unknown" id="5jeq8P" />
            )}
          </A>
        );
      },
    },
    {
      Header: "Transactions",
      accessor: "count",
      disableSortBy: true,
      noWrap: true,
      align: ColumnAlign.RIGHT,
      headClassName: "text-xs",
      className: "text-xs",
      Cell: ({ row }) => <>{row.original.count}</>,
    },
  ];
};
