import { IconProps } from "@fonoa/ui-components/icons";
import { DataAttributes } from "@fonoa/ui-components/types";
import { convertDataAttributes } from "@fonoa/ui-components/utils";
import classNames from "classnames";
import { ComponentType, PropsWithChildren } from "react";

export interface BigToggleItem<KeysType extends string> {
  key: KeysType;
  label: string;
  leftIcon?: ComponentType<PropsWithChildren<IconProps>>;
  leftIconProps?: IconProps;
  onClick: () => void;
  disabled?: boolean;
}

export interface BigToggleProps<KeysType extends string> {
  items: ReadonlyArray<BigToggleItem<KeysType>>;
  selectedItemKey: KeysType;
  onChange?: (itemKey: KeysType) => void;
  dataAttributes?: DataAttributes;
}

export default function BigToggle<KeysType extends string = string>({
  items,
  selectedItemKey,
  onChange,
  dataAttributes,
}: BigToggleProps<KeysType>) {
  const dataAttr = convertDataAttributes(dataAttributes);
  return (
    <div
      className={classNames(
        `grid-flow-cols grid divide-x divide-blueGray400/50 text-sm`,
        `grid-cols-${items.length}`
      )}
      {...dataAttr}
    >
      {items.map(({ onClick, key, label, leftIcon: LeftIcon, leftIconProps, disabled }, index) => (
        <div
          key={key}
          className={classNames("flex h-8 items-center justify-center px-3 text-center", {
            "bg-blueGray600 text-white": selectedItemKey === key && !disabled,
            "bg-blueGray25 text-blueGray600 cursor-pointer": selectedItemKey !== key && !disabled,
            "rounded-l-sm": index === 0,
            "rounded-r-sm": index === items.length - 1,
            "bg-blueGray25 text-blueGray400 cursor-not-allowed": disabled,
          })}
          aria-disabled={disabled}
          onClick={() => {
            if (selectedItemKey !== key && !disabled) {
              onClick();
              onChange && onChange(key);
            }
          }}
        >
          {LeftIcon && (
            <LeftIcon
              {...leftIconProps}
              className={classNames(leftIconProps?.className, "mr-1.5")}
            />
          )}
          <span>{label}</span>
        </div>
      ))}
    </div>
  );
}
