import { Select } from "@fonoa/ui-components/select";
import { Typography } from "@fonoa/ui-components/typography";
import moment from "moment";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ReportingDashboardFilterProps } from "./ReportingDashboardFilters";

const INITIAL_PERIOD = "week_to_date";
const format = (d: moment.Moment) => d.format("DD/MM/YYYY");

interface Props {
  onChange: (v: ReportingDashboardFilterProps) => void;
  values: ReportingDashboardFilterProps;
}
export const getDefaultDateFilter = () => {
  return {
    periodKey: INITIAL_PERIOD,
    ...getPeriodOptions()[INITIAL_PERIOD as keyof ReturnType<typeof getPeriodOptions>],
  };
};

const getMultiDayPeriodDetails = ({ from, to }: { from: moment.Moment; to: moment.Moment }) => {
  return {
    periodFormatted: `${format(from)}-${format(to)}`,
    dateFrom: from.startOf("day").toDate(),
    dateTo: to.endOf("day").toDate(),
  };
};

const getPeriodOptions = () => {
  const now = moment().utc();
  return {
    today: {
      periodName: <FormattedMessage defaultMessage="Today" id="zWgbGg" />,
      periodFormatted: format(now),
      dateFrom: now.clone().startOf("day").toDate(),
      dateTo: now.clone().endOf("day").toDate(),
    },
    week_to_date: {
      periodName: <FormattedMessage defaultMessage="Week to date" id="jhcZE/" />,
      ...getMultiDayPeriodDetails({ from: now.clone().startOf("week"), to: now }),
    },
    month_to_date: {
      periodName: <FormattedMessage defaultMessage="Month to date" id="rwUDGW" />,
      ...getMultiDayPeriodDetails({ from: now.clone().startOf("month"), to: now }),
    },
    quarter_to_date: {
      periodName: <FormattedMessage defaultMessage="Quarter to date" id="a7RVBv" />,
      ...getMultiDayPeriodDetails({ from: now.clone().startOf("quarter"), to: now }),
    },
    year_to_date: {
      periodName: <FormattedMessage defaultMessage="Year to date" id="zvczgi" />,
      ...getMultiDayPeriodDetails({ from: now.clone().startOf("year"), to: now }),
    },
  };
};

const DateRangeSearch = ({ onChange, values }: Props) => {
  const periods = useMemo(() => getPeriodOptions(), []);

  const options = useMemo(
    () =>
      Object.keys(periods).map((key) => {
        const period = periods[key as keyof ReturnType<typeof getPeriodOptions>];

        return {
          label: (
            <div className="flex">
              <span className="mr-0.5">{period.periodName}</span>
              <Typography className="text-blueGray600">{` (${period.periodFormatted})`}</Typography>
            </div>
          ),
          value: key,
        };
      }),
    [periods]
  );

  const [period, setPeriod] = useState(INITIAL_PERIOD);
  const handleChangePeriod = (value: string) => {
    setPeriod(value);
    const period = periods[value as keyof ReturnType<typeof getPeriodOptions>];
    onChange({ ...values, periodKey: value, dateFrom: period.dateFrom, dateTo: period.dateTo });
  };
  return (
    <div className="flex items-center gap-x-4">
      <Select
        multiple={false}
        onChange={(e) => {
          handleChangePeriod(e);
        }}
        dataAttributes={{
          cy: "reporting-dashboard-period-select",
        }}
        value={period}
        options={options}
        size="MEDIUM"
        width="w-80"
      />
    </div>
  );
};

export default DateRangeSearch;
