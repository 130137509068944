import { Typography } from "@fonoa/ui-components/typography";
import classNames from "classnames";
import Image from "next/image";
import { useIntl } from "react-intl";

import getCountryFlagPath from "@/lib/countryFlagsPath";

import { useCountryOptions } from "./SelectOptions";

interface Props {
  country_code: string;
  withBoder?: boolean;
  withHover?: boolean;
}

const CountryFlagLabel = ({ country_code, withBoder, withHover }: Props) => {
  const intl = useIntl();
  const { countryOptions } = useCountryOptions();

  const country = countryOptions.find((i) => i.value === country_code.toLowerCase());
  return (
    <div
      className={classNames("flex items-center", { "hover:bg-blueGray25 rounded px-1": withHover })}
    >
      <Image
        width={16}
        height={16}
        src={getCountryFlagPath(country_code, "1x1")}
        className={classNames("h-auto max-w-full rounded-full", {
          "border border-blueGray100": withBoder,
        })}
        alt={intl.formatMessage(
          {
            defaultMessage: "{country} flag",
            id: "xPDVMQ",
          },
          { country: country_code }
        )}
      />
      <Typography className="ml-2">{country?.label}</Typography>
    </div>
  );
};

export default CountryFlagLabel;
