import AccountTypeSearch from "./AccountTypeSearch";
import CountriesSearch from "./CountriesSearch";
import CurrencySearch from "./CurrencySearch";
import DateRangeSearch, { getDefaultDateFilter } from "./DateRangeSearch";

export type ReportingDashboardFilterProps = {
  periodKey: string;
  dateFrom: Date;
  dateTo: Date;
  direction?: string;
  countryCode?: string;
  currencyCode?: string;
};

interface Props {
  onChange: (v: ReportingDashboardFilterProps) => void;
  values: ReportingDashboardFilterProps;
}

export const getDefaultFilter = () => {
  return getDefaultDateFilter;
};

export const ReportingDashboardFilters = ({ onChange, values }: Props) => {
  return (
    <form className="mt-4" onSubmit={(e) => e.preventDefault()}>
      <div className="mt-5 flex h-10 items-center">
        <div className="mr-3">
          <DateRangeSearch onChange={onChange} values={values} />
        </div>
        <div className="mr-3">
          <CountriesSearch onChange={onChange} values={values} />
        </div>
        <div className="mr-3">
          <AccountTypeSearch onChange={onChange} values={values} />
        </div>
        <div className="mr-3">
          <CurrencySearch onChange={onChange} values={values} />
        </div>
      </div>
    </form>
  );
};

export default ReportingDashboardFilters;
