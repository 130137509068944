import { SpacingProps } from "@fonoa/ui-components/types";
import { getSpacingClass } from "@fonoa/ui-components/utils";
import classNames from "classnames";

interface LoaderBarProps extends SpacingProps {
  className?: string;
  animated?: boolean;
  height?: `h-${string}`;
  width?: `w-${string}`;
}

export default function LoaderBar({
  className,
  animated,
  width = "w-full",
  height = "h-2",
  spacing,
}: LoaderBarProps) {
  return (
    <div
      className={classNames(
        "bg-blueGray200",
        width,
        height,
        getSpacingClass(spacing),
        {
          "animate-pulse": animated,
          "my-5": typeof spacing?.my !== "number",
        },
        className
      )}
    />
  );
}
