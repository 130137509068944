import { Typography } from "@fonoa/ui-components/typography";
import { BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { LineProps, PointTooltipProps, ResponsiveLine } from "@nivo/line";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { reportingRateMock } from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.fixtures";

import { parseChartData } from "./ReportingRateChart.utils";

interface LinePoint {
  x: number | string;
  y: number;
}

interface LineElement {
  id: string;
  data: LinePoint[];
}

export interface LineChartProps extends LineProps {
  data: LineElement[];
  height: number;
  loading: boolean;
}

const LineChartTooltip = ({ props, data }: { props: PointTooltipProps; data: LineElement[] }) => {
  const xValue = format(new Date(props.point.data.x), "yyyy-MM-dd");

  const reportedDataSet = data?.find((d) => d.id === "reported");
  const errorDataSet = data?.find((d) => d.id === "error");

  const reportedValue = reportedDataSet?.data?.find((d) => d.x === xValue)?.y;
  const errorValue = errorDataSet?.data?.find((d) => d.x === xValue)?.y;

  return (
    <div className="rounded-sm border border-blueGray100 bg-white py-1 px-2 shadow-1">
      <Typography variant="h6" color="text-blueGray600">
        {format(new Date(xValue), "dd/MM/yyyy")}
      </Typography>
      <Typography color="text-blueGray800" variant="p">
        {reportedValue ? <FormattedNumber value={reportedValue} maximumFractionDigits={0} /> : "0"}{" "}
        <FormattedMessage defaultMessage="reported" id="FJZol1" />
      </Typography>
      <Typography color="text-blueGray800" variant="p">
        {errorValue ? <FormattedNumber value={errorValue} maximumFractionDigits={0} /> : "0"}{" "}
        <FormattedMessage defaultMessage="with error" id="I9TSOc" />
      </Typography>
    </div>
  );
};

const BarChartTooltip = ({
  props,
}: {
  props: BarTooltipProps<{ error: string; reported: string }>;
}) => {
  return (
    <div className="rounded-sm border border-blueGray100 bg-white py-1 px-2 shadow-1">
      <Typography variant="h6" color="text-blueGray600">
        {format(new Date(), "dd/MM/yyyy")}
      </Typography>
      <Typography color="text-blueGray800" variant="p">
        {props.value ? <FormattedNumber value={props.value} maximumFractionDigits={0} /> : "0"}{" "}
        {props.id === "reported" ? (
          <FormattedMessage defaultMessage="reported" id="FJZol1" />
        ) : null}
        {props.id === "error" ? <FormattedMessage defaultMessage="with error" id="I9TSOc" /> : null}
      </Typography>
    </div>
  );
};

const getSharedChartProps = ({ data }: { data: LineElement[] }): Partial<LineProps> => ({
  isInteractive: true,
  margin: { top: 5, right: 20, bottom: 34, left: 34 },
  xFormat: "time:%Y-%m-%d",
  xScale: { type: "time", format: "%Y-%m-%d", precision: "day", useUTC: true },
  yScale: { type: "linear", min: 0 },
  lineWidth: 2,
  curve: "linear",
  pointSize: 4,
  pointColor: "#DCE6F9",
  pointBorderWidth: 1,
  pointBorderColor: { from: "serieColor" },
  enableGridX: false,
  gridYValues: 4,
  tooltip: (props) => <LineChartTooltip props={props} data={data} />,
  enableArea: true,
  areaOpacity: 0.5,
  theme: {
    tooltip: {
      container: { padding: 0, minHeight: "min-content" },
    },
    fontSize: 10,
    axis: {
      ticks: {
        text: {
          fill: "#96AAB9",
        },
      },
    },
    grid: {
      line: {
        stroke: "#96AAB9",
        strokeWidth: 1,
        strokeDasharray: "3 3",
      },
    },
  },
});

const loadingRenderTickBottom: LineProps["axisBottom"] = {
  tickValues: 5,
  renderTick: ({ opacity, x, y }) => {
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <rect
          width="32"
          height="10"
          x="-16"
          y="12"
          rx="0"
          ry="0"
          fill="#E3E8EC"
          strokeWidth="0"
          stroke="#E3E8EC"
        />
      </g>
    );
  },
};

const loadingRenderTickLeft: LineProps["axisLeft"] = {
  tickValues: 3,
  renderTick: ({ opacity, x, y }) => {
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <rect
          width="32"
          height="10"
          x="-40"
          y="-5"
          rx="0"
          ry="0"
          fill="#E3E8EC"
          strokeWidth="0"
          stroke="#E3E8EC"
        />
      </g>
    );
  },
};

function LineChartEmpty() {
  return (
    <>
      <ResponsiveLine
        data={parseChartData(reportingRateMock.data?.chart_items)}
        axisLeft={{ tickValues: 3 }}
        useMesh={true}
        axisBottom={{
          tickValues: 5,
          format: (value) => {
            return format(new Date(value), "MMM d");
          },
          tickSize: 12,
        }}
        {...getSharedChartProps({ data: [] })}
        colors={["transparent", "transparent"]}
        pointColor="transparent"
      />
      <div className="absolute inset-0 flex items-center justify-center text-center">
        <Typography variant="p" color="text-blueGray900">
          <FormattedMessage defaultMessage="No data available for this period" id="OlW5Z8" />
        </Typography>
      </div>
    </>
  );
}

function LineChartLoading() {
  return (
    <ResponsiveLine
      data={parseChartData(reportingRateMock.data?.chart_items)}
      colors={["#D7DEE3", "#D7DEE3"]}
      axisBottom={loadingRenderTickBottom}
      axisLeft={loadingRenderTickLeft}
      {...getSharedChartProps({ data: [] })}
    />
  );
}

export default function LineChart({ height, data, loading }: LineChartProps) {
  const [chartData, setChartData] = useState<LineElement[]>();

  const isChartEmpty = data?.every(
    (d) => d.data?.reduce((total, point) => total + point.y, 0) === 0
  );

  // The chart requires a local state to ensure proper rendering on data change
  // See -> https://github.com/plouc/nivo/issues/1006#issuecomment-797091909
  useEffect(() => {
    setChartData(data);
  }, [data, setChartData]);

  const sharedChartProps = (chartData?.length && getSharedChartProps({ data: chartData })) || {};

  return (
    <div
      className="relative"
      style={{ height: height + "px" }}
      aria-hidden="true" /* Hiding for screen readers as nivo charts is currenty not screen reader friendly */
    >
      {loading && <LineChartLoading />}
      {!loading && isChartEmpty ? <LineChartEmpty /> : null}
      {/* Show bar chart when there's only one day period to show */}
      {!loading && !isChartEmpty && chartData?.[0]?.data?.length === 1 ? (
        <ResponsiveBar
          data={[
            {
              error: `${chartData?.find((d) => d.id === "error")?.data?.[0]?.y}`,
              reported: `${chartData?.find((d) => d.id === "reported")?.data?.[0]?.y}`,
            },
          ]}
          keys={["error", "reported"]}
          colors={["#F29888", "#A3DCBA"]}
          axisLeft={{ tickValues: 3 }}
          axisBottom={null}
          isInteractive={true}
          indexBy="reported"
          padding={0.7}
          tooltip={(props) => <BarChartTooltip props={props} />}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          margin={sharedChartProps.margin}
          theme={sharedChartProps.theme}
        />
      ) : null}
      {!loading && !isChartEmpty && chartData?.length && chartData?.[0]?.data?.length > 1 ? (
        <ResponsiveLine
          data={chartData}
          colors={["#F29888", "#A3DCBA"]}
          axisLeft={{ tickValues: 3 }}
          axisBottom={{
            tickValues: 5,
            format: (value) => {
              return format(new Date(value), "MMM d");
            },
          }}
          useMesh={true}
          {...sharedChartProps}
        />
      ) : null}
    </div>
  );
}
