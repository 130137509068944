import { Typography } from "@fonoa/ui-components/typography";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { ReportingRateResponse } from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.types";
import { Loader } from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.utils";

import LineChart from "./LineChart";
import { parseChartData } from "./ReportingRateChart.utils";

export interface ReportingRateProps {
  reportingRate?: ReportingRateResponse;
  reportingRateLoading: boolean;
  reportingRateError: boolean;
}

export const ReportingRateChart = ({
  reportingRate,
  reportingRateLoading,
  reportingRateError,
}: ReportingRateProps) => {
  const data = reportingRate?.data;
  const totalTransactions = (data?.total_reported || 0) + (data?.total_error || 0);
  const ratePercentage = ((data?.total_reported || 0) / totalTransactions) * 100 || 0;

  return (
    <>
      <div className="mb-1 flex">
        <Typography
          variant="h5"
          fontWeight="font-medium"
          color="text-blueGray900"
          spacing={{ mr: 2 }}
        >
          <FormattedMessage defaultMessage="Reporting rate" id="H8G4fe" />
        </Typography>
      </div>
      <div className="mt-1">
        {!reportingRateError && !reportingRateLoading ? (
          <Loader isLoading={reportingRateLoading} size="LARGE">
            <Typography variant="h3" fontWeight="font-medium" color="text-blueGray800">
              <FormattedNumber value={ratePercentage} maximumFractionDigits={2} />%
            </Typography>
          </Loader>
        ) : null}
      </div>
      <div className="relative mt-4 h-[240px]">
        {reportingRateError ? (
          <div className="flex h-full w-full items-center justify-center">
            <Typography variant="h6" color="text-blueGray900">
              <FormattedMessage
                defaultMessage="Something went wrong loading the reporting rate data. Please try again later or contact us."
                id="iau4fn"
              />
            </Typography>
          </div>
        ) : (
          <LineChart
            data={parseChartData(data?.chart_items)}
            height={240}
            loading={reportingRateLoading}
          />
        )}
      </div>
      <div className="mt-4">
        {!reportingRateError ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Typography variant="h6" color="text-blueGray900" spacing={{ mr: 1 }}>
                <FormattedMessage defaultMessage="Total:" id="q4EmsW" />
              </Typography>
              <Loader isLoading={reportingRateLoading}>
                <Typography variant="h6" color="text-blueGray900" fontWeight="font-medium">
                  <FormattedNumber value={totalTransactions} maximumFractionDigits={0} />
                </Typography>
              </Loader>
            </div>
            <div className="flex">
              <div className="mr-4 flex items-center">
                <div className="mr-2 h-2 w-2 rounded-full bg-green200" />
                <Typography variant="h6" color="text-blueGray900" spacing={{ mr: 1 }}>
                  <FormattedMessage defaultMessage="Reported:" id="iCZ7Vo" />
                </Typography>
                <Loader isLoading={reportingRateLoading}>
                  <Typography variant="h6" color="text-blueGray900" fontWeight="font-medium">
                    <FormattedNumber value={data?.total_reported || 0} maximumFractionDigits={0} />
                  </Typography>
                </Loader>
              </div>
              <div className="flex items-center">
                <div className="mr-2 h-2 w-2 rounded-full bg-red200" />
                <Typography variant="h6" color="text-blueGray900" spacing={{ mr: 1 }}>
                  <FormattedMessage defaultMessage="Error:" id="5elgIe" />
                </Typography>
                <Loader isLoading={reportingRateLoading}>
                  <Typography variant="h6" color="text-blueGray900" fontWeight="font-medium">
                    <FormattedNumber value={data?.total_error || 0} maximumFractionDigits={0} />
                  </Typography>
                </Loader>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
