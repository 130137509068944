import { useIntl } from "react-intl";

import FilterItem from "@/components/Filters/FilterItem";
import SelectableList from "@/components/Form/SelectableList";

import { ReportingDashboardFilterProps } from "./ReportingDashboardFilters";

interface Props {
  onChange: (v: ReportingDashboardFilterProps) => void;
  values: ReportingDashboardFilterProps;
}

const AccountTypeSearch = ({ onChange, values }: Props) => {
  const intl = useIntl();
  const ACCOUNT_TYPES = [
    {
      label: intl.formatMessage({ defaultMessage: "AR", id: "BrkH6g" }),
      value: "sent",
    },
    {
      label: intl.formatMessage({ defaultMessage: "AP", id: "JWHn/U" }),
      value: "received",
    },
  ];
  const selectedAccountType = ACCOUNT_TYPES.find(({ value }) => value === values.direction);

  return (
    <FilterItem
      title={intl.formatMessage({ defaultMessage: "Account Type", id: "K1uUiB" })}
      renderCurrentValue={() => selectedAccountType?.label}
      onApply={(val) => onChange({ ...values, direction: val })}
      value={values.direction}
      active={Boolean(values.direction)}
      dataAttributes={{ cy: "filter-account-type" }}
      component={(itemValue, onChange) => (
        <SelectableList
          items={ACCOUNT_TYPES}
          onChange={onChange}
          dataAttributes={{ cy: "options-account-type" }}
          value={itemValue}
        />
      )}
      footerStyle="clean"
      withApplyButton={false}
      panelWidth="w-52"
    />
  );
};

export default AccountTypeSearch;
