import { format } from "date-fns";

export interface ReportingRateItem {
  date: string;
  error: number;
  reported: number;
}

export function parseChartData(data?: ReportingRateItem[]) {
  if (!data?.length) {
    return [
      {
        id: "error",
        data: [],
      },
      {
        id: "reported",
        data: [],
      },
    ];
  }

  return [
    {
      id: "error",
      data: data.map(({ error, date }) => ({
        x: format(new Date(date), "yyyy-MM-dd"),
        y: error,
      })),
    },
    {
      id: "reported",
      data: data.map(({ reported, date }) => ({
        x: format(new Date(date), "yyyy-MM-dd"),
        y: reported,
      })),
    },
  ];
}
