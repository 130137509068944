import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { ErrorCategory } from "@/lib/types/transaction";

export const getErrorMessageFromCode = (code: ErrorCategory): ReactNode => {
  switch (code) {
    case ErrorCategory.TaxAuthority:
      return <FormattedMessage defaultMessage="TA error" id="L8/tgO" />;
    case ErrorCategory.Validation:
      return <FormattedMessage defaultMessage="Validation error" id="tzKb9M" />;
    case ErrorCategory.General:
      return <FormattedMessage defaultMessage="Other" id="/VnDMl" />;
    default:
      return code;
  }
};
