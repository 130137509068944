import { A } from "@fonoa/ui-components/typography/A";
import * as Sentry from "@sentry/nextjs";
import { FormattedMessage } from "react-intl";

import type { DashboardAlert } from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.types";
import { routes } from "@/lib/routes";

export const AlertType = ({ type }: Pick<DashboardAlert, "type">) => {
  switch (type) {
    case "invalid_tax_id":
      return <FormattedMessage defaultMessage="Invalid Tax ID" id="xvekaL" />;
    case "certificate_expiration":
      return <></>;
    default: {
      Sentry.captureException(new Error(`Unable to parse Reporting Dashboard Alert type: ${type}`));
      return <>{type}</>;
    }
  }
};

export const AlertCount = ({ type, count }: DashboardAlert) => {
  switch (type) {
    case "invalid_tax_id":
      return (
        <A href={routes.lookupValidationHistorySingle} fontWeight="normal">
          <FormattedMessage
            defaultMessage="{count, plural, =0 {entities} one {# entity} other {# entities}}"
            id="DEgrfl"
            values={{
              count,
            }}
          />
        </A>
      );
    case "certificate_expiration":
      return <></>;
    default: {
      Sentry.captureException(new Error(`Unable to parse Reporting Dashboard Alert type: ${type}`));
      return <>{count}</>;
    }
  }
};
