import { ReactNode } from "react";

import LoaderBar from "@/components/Loaders/LoaderBar";

export const Loader = ({
  isLoading,
  size = "REGULAR",
  children,
}: {
  isLoading: boolean;
  size?: "REGULAR" | "LARGE";
  children: ReactNode;
}) => {
  if (isLoading) {
    return (
      <LoaderBar
        width={size === "LARGE" ? "w-20" : "w-14"}
        height={size === "LARGE" ? "h-5" : "h-3"}
        animated
        spacing={size === "LARGE" ? { mt: 2.5, mb: 5.5 } : { my: 0.5, mx: 0.5 }}
      />
    );
  }

  return <>{children}</>;
};
