import { useIntl } from "react-intl";

import FilterItem from "@/components/Filters/FilterItem";
import SelectableList from "@/components/Form/SelectableList";
import CountryFlagLabel from "@/components/Utils/CountryFlagLabel";
import { useCountryOptions } from "@/components/Utils/SelectOptions";
import { trpc } from "@/utils/trpc";

import { ReportingDashboardFilterProps } from "./ReportingDashboardFilters";

interface Props {
  onChange: (v: ReportingDashboardFilterProps) => void;
  values: ReportingDashboardFilterProps;
}

const CountriesSearch = ({ onChange, values }: Props) => {
  const intl = useIntl();

  const { countryOptions } = useCountryOptions({ includeCountryCodeOnLabel: true });

  const { data: codes } = trpc.eInvoicing.countryCodes.useQuery({
    dateFrom: values.dateFrom?.toISOString(),
    dateTo: values.dateTo?.toISOString(),
  });

  const options =
    countryOptions.filter(
      (o) => codes?.data && codes.data.some((d) => d.code.toUpperCase() === o.value.toUpperCase())
    ) || [];

  return (
    <FilterItem
      dataAttributes={{ cy: "country-filter" }}
      onApply={(val) => onChange({ ...values, countryCode: val })}
      active={Boolean(values.countryCode)}
      withApplyButton={false}
      title={intl.formatMessage({ defaultMessage: "Country", id: "vONi+O" })}
      value={values.countryCode}
      renderCurrentValue={() =>
        values.countryCode && (
          <div className="flex items-center">
            <CountryFlagLabel country_code={values.countryCode} />
          </div>
        )
      }
      component={(itemValue, onChange) => (
        <div className="max-h-56 overflow-y-scroll">
          <SelectableList
            searchable
            showSelectedFirst
            items={options}
            onChange={onChange}
            value={itemValue}
          />
        </div>
      )}
    />
  );
};

export default CountriesSearch;
